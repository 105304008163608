/**
 * @ComponentFor InstagramBlockViewModel
 */

import React from 'react';
import { styled } from '@glitz/react';
import { Link } from '@avensia/scope';
import RequiredPropType from './InstagramBlockViewModel.type';
import * as style from '../../Style';
import Image, { Ratio as ImageRatio, Preset as ImagePreset } from 'Shared/Image/Ratio';
import { BOX_ITEM_CLASSNAME } from 'Shared/BoxLayout';

type PropType = RequiredPropType & {
  currentBreakpoint: number;
};

const Post = styled.div({
  flexBasis: '100%',
  margin: { y: style.medium, x: 0 },
  [style.minMicroMediaQuery]: {
    flexBasis: '48%',
  },
  [style.minTinyMediaQuery]: {
    flexBasis: '32%',
  },
  [style.minMediumMediaQuery]: {
    flexBasis: '8%',
  },
});

const Placeholder = styled(Post, {
  margin: { y: 0, x: 0 },
});

function renderPlaceholders(numOfPlaceholders: number) {
  return new Array(numOfPlaceholders).fill(0).map((elem, index) => <Placeholder key={index} />);
}

const InstagramBlock = (props: PropType) => {
  const maxItems = 12;
  const slicedImages = !!props.instagramMedia && props.instagramMedia.slice(0, maxItems);
  const placeholders = maxItems - slicedImages.length;
  const mappedPosts =
    !!props.instagramMedia &&
    slicedImages.map(post => {
      if (post.mediaType === 'image') {
        return (
          <Post key={post.mediaUrl.url}>
            <Link to={post.instagramLink}>
              <Image itemProp="image" src={post.mediaUrl.url} ratio={ImageRatio.OneToOne} preset={ImagePreset.Tiny} />
            </Link>
          </Post>
        );
      } else if (post.mediaType === 'video') {
        return (
          <Post key={post.mediaUrl.url}>
            <Link to={post.instagramLink}>
              <video src={post.mediaUrl.url} poster={post.videoPosterUrl} width="100%" controls preload="none" />
            </Link>
          </Post>
        );
      }
    });

  return (
    mappedPosts &&
    mappedPosts.length > 0 && (
      <styled.Div
        css={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'space-between',
          marginTop: style.pixelsToUnit(30),
        }}
        className={BOX_ITEM_CLASSNAME}
      >
        {mappedPosts}
        {placeholders > 0 && renderPlaceholders(placeholders)}
      </styled.Div>
    )
  );
};

export default InstagramBlock;
